import { Button as LibButton, IButton } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './Button.module.css';

interface IFButton extends PropsWithChildren<IButton> {
    transparent?: boolean;
    type?: string;
    animation?: boolean;
}

const Button: FC<IFButton> = ({
    onClick,
    backgroundColor,
    textColor,
    hoverColor,
    size,
    text,
    disabled,
    isLoading,
    withPulsating,
    children,
    rightIcon,
    centerIcon,
    dataLocator,
    borderRadius,
    className,
    mb,
    transparent,
    animation,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.btnContainer}>
            <LibButton
                {...(text && { text: t(text) })}
                onClick={onClick}
                backgroundColor={backgroundColor}
                textColor={textColor}
                hoverColor={hoverColor}
                size={size}
                disabled={disabled}
                isLoading={isLoading}
                withPulsating={withPulsating}
                rightIcon={rightIcon}
                centerIcon={centerIcon}
                dataLocator={dataLocator}
                borderRadius={borderRadius}
                className={cx(className, styles.btn, { [styles.transparent]: transparent, [styles.animation]: animation })}
                mb={mb}
            >
                {children}
            </LibButton>
        </div>
    );
};

export default Button;
