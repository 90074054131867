import React from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Loader.module.css';

import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Loader = ({ isLoading, additionalClass }) => {
    const loaderStyles = cx({ loader: true, [additionalClass]: additionalClass });

    return (
        <If condition={isLoading}>
            <div className={loaderStyles} />
        </If>
    );
};

Loader.propTypes = {
    isLoading: PropTypes.bool,
    additionalClass: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Loader;
