import { Text as WText } from 'wikr-core-components';
import { IText as IWText } from 'wikr-core-components';
import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './Text.module.css';


interface IText extends IWText {
    title?: boolean,
}

const Text = (props: IText): ReactElement => (
    <div className={cx(styles.wrapper, styles[props.title ? 'title' : 'regular'])}>
        <WText {...props} />
    </div>
);

Text.defaultProps = {
    title: false
};

export default Text;
