import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { persistStore, persistReducer } from 'redux-persist';

import sagas from './redux/sagas';
import rootReducer from './redux/rootReducer';

// Remove merchantData field from persist store
const saveMerchantDataBlacklistFilter = createBlacklistFilter('payment', ['merchantData']);
const userBlackListFilter = createBlacklistFilter('user', ['emailError']);

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const persistConfig = {
    key: 'root',
    storage,
    transforms: [saveMerchantDataBlacklistFilter, userBlackListFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk)));

    const persistor = persistStore(store);

    sagaMiddleware.run(sagas);

    return { store, persistor };
}
