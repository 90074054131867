import Base from './Base';

class UserApi extends Base {
  async init(payload: any) {
    return await this.apiClient.post('payments/init-payment', payload);
  }

  async validate(payload: any) {
    return await this.apiClient.post('payments/validate', payload);
  }

  async validateResubscribe(payload: any) {
    return await this.apiClient.post('payments/validate?resubscribe=true', payload);
  }

  async oneClickPayment(payload: any) {
    return await this.apiClient.post('payments/recurring', payload);
  }

  async getPaymentIntentWithSignature(payload: any) {
    return await this.apiClient.post('payments/merchant-data', payload);
  }

  async validateApplePayMerchant(validationUrl: any) {
    return await this.apiClient.post('apple-pay/validate-merchant', validationUrl);
  }

  replaceSubscription(payload: any) {
    return this.apiClient.post('replace-subscription', payload);
  }
}

export default UserApi;
