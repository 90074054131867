import { put, putResolve, select, takeLatest } from 'redux-saga/effects';



import { setUserEmail, signUp } from 'redux/User/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { SET_COMPLETE_OBS, SET_TESTANIA_LOGIN_STATUS } from 'redux/Testania/actionTypes';
import { SKIP_ONBOARDING } from 'redux/Onboadring/actionTypes';
import { skipOnboarding } from 'redux/Onboadring/actions';

import { GET_PREGNANT_GOAL, TRACK_CYCLE_GOAL, TRACK_PREGNANCY_GOAL } from 'constants/userGoals';
import { DEFAULT_FLOW } from 'constants/defaultFlows';

import { getUserGoalByBranchName } from 'helpers/userGoal';

export function* skip({ payload }: ReturnType<typeof skipOnboarding>) {
  const generatedEmail = `service-account-sso.femia-autotest+${Date.now()}@welltech.com`;
  const branchName = localStorage.getItem('branch-name');
  const defaultBranchName = localStorage.getItem('currentBranchName');
  const testName = localStorage.getItem('test-name');

  const userGoal = getUserGoalByBranchName(branchName);

  yield put(setUserEmail(generatedEmail));

  const gp_props = {
    ob_goal_gp_new: ['gp_faster'],
    ttc_duration: 'to_3_months',
    // on backend prefix should be _gp, not _gn
    ob_gp_health_issues: ['endometriosis'],
    ...(branchName === 'motherhood' && { funnel_topic: 'motherhood' }),
  };

  const tc_props = {
    ob_goal_sex_new: ['multiple_orgasms'],
    ob_relationships: 'married',
    ob_sex_pregnant: 'yes',
    ob_sex_menstruation: 'regular',
    ...(branchName === 'lv' && { funnel_topic: 'sex' }),
  };

  const pg_props = {};

  const getUserPropsByUserGoal = (userGoal: string) => {
    if (userGoal === GET_PREGNANT_GOAL) return gp_props;
    if (userGoal === TRACK_CYCLE_GOAL) return tc_props;
    if (userGoal === TRACK_PREGNANCY_GOAL) return pg_props;

    return {};
  };

  const payloadData = {
    email: generatedEmail,
    test_name: testName || DEFAULT_FLOW,
    branch_name: branchName || defaultBranchName,
    main_user_goal: userGoal,
    default_cycle_regularity: null,
    year_of_birth: 2000,
    default_cycle_length: 28,
    default_period_length: 5,
    ob_health_problems: [
      'anemia',
    ],
    app_improvement_consent: true,
    email_sending_consent: true,
    has_been_pregnant: null,
    ob_lifestyle_adjustment: null,
    ob_supplements: 'no',
    ob_disorders: [
      'pcos',
    ],
    ob_job_type: null,
    ob_symptoms: [
      'spotting',
    ],
    ob_height_value: 150,
    ob_weight_value: 68,
    ...(getUserPropsByUserGoal(userGoal)),
  };

  yield putResolve(
    signUp({
      payload: payloadData,
      toNextPage: () => payload.reload(),
    }),
  );

  try {
    const completeObs: string = yield select(selectTestaniaName);

    yield put({
      type: SET_TESTANIA_LOGIN_STATUS,
      payload: true,
    });
    yield put({
      type: SET_COMPLETE_OBS,
      payload: completeObs,
    });
  } catch (error) {
    console.warn(error);
  }
}

export const skipOnBoardingSaga = [takeLatest(SKIP_ONBOARDING, skip)];
