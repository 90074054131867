import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'constants/sentry';

import { selectUser } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';
import {
  changePaymentMethod,
  initPaymentGenerator as initPaymentGeneratorAction,
  initPayPal as initPayPalAction,
  setCustomPaymentButton,
  setIsPaymentScreen,
  setLoadingStatus,
  setResubModalConfig,
} from 'redux/Payment/actions';

import {
  DAYS_PER_MONTH,
  PAYMENT_TYPES,
  PAYPAL_LITERAL,
  SOLID_DECLINED_UNKNOWN_CODES,
  SOLID_ERROR_CODES,
} from 'constants/payments';

import sentry from 'services/Sentry';

import useSolidPaymentPreInit from 'hooks/useSolidPaymentPreInit';

import { Currency, CurrentProduct, PaymentMethod } from 'types/payments/payments';

import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';
import { useValidatePayment } from './useValidatePayment';

export function usePaymentLib({ pageInfo, toNextPage, hasCustomButton = true, analytic }: any): any {
  const dispatch = useDispatch();
  const { user_id, country } = useSelector(selectUser);
  const {
    isLoading,
    currency,
    currentProduct,
    validatePaymentData,
    paymentMethod,
    resubModalConfig: {
      isResubscribed,
    },
  } = useSelector(selectPayment);

  const isResubscribedRef = useRef(false);
  const validatePayment = useValidatePayment();

  const handleChangePaymentMethod = (type: PaymentMethod) => dispatch(changePaymentMethod(type));

  const getCurrencyName = (currency: Currency): string => currency.name;

  const paymentType = pageInfo.products[0].payment_type;

  useSolidPaymentPreInit({ initPayPal, initPaymentGenerator });

  useEffect(() => {
    if (hasCustomButton) {
      dispatch(setCustomPaymentButton(true));

      return () => {
        dispatch(setCustomPaymentButton(false));
      };
    }
  }, []);

  useEffect((): (() => void) => {
    dispatch(setLoadingStatus(true));
    dispatch(setIsPaymentScreen(true));

    return () => dispatch(setIsPaymentScreen(false));
  }, []);

  useEffect(() => {
    if (isResubscribed) {
      isResubscribedRef.current = true;
    }
  }, [isResubscribed]);

  useEffect(() => {
    // @ts-ignore
    if (validatePaymentData && Boolean(validatePaymentData?.result)) {
      if (pageInfo.config?.is_resubscribe_popup && (currentProduct?.trial === DAYS_PER_MONTH) && !isResubscribedRef.current) {
        dispatch(setResubModalConfig({
          isOpen: true,
          resubProduct1m: currentProduct as CurrentProduct,
          // @ts-ignore
          toNextPage: () => toNextPage({ isValid: validatePaymentData?.result }),
        }));
      } else {
        toNextPage({
          // @ts-ignore
          isValid: validatePaymentData?.result,
        });
      }
    }
  }, [validatePaymentData]);

  function initPayPal() {
    const meta = {
      userId: user_id,
      payment_method: PAYMENT_TYPES.PAYPAL,
      // @ts-ignore
      price: currentProduct?.price || 1,
      country,
      currency: getCurrencyName(currency),
      successHandler: (data: DataForValidate) => {
        if (data?.error?.code) {
          if (SOLID_DECLINED_UNKNOWN_CODES.includes(data.error.code)) {
            initPayPal();

            return;
          }

          dispatch(setLoadingStatus(false));
          dispatch(changePaymentMethod(PAYPAL_LITERAL));

          if (SOLID_ERROR_CODES.includes(data?.error?.code)) {
            sentry.logError(
              new Error('PayPal error'),
              SENTRY_PAYMENT,
              ERROR_LEVELS.CRITICAL,
              {
                // @ts-ignore
                ...currentProduct,
                ...meta,
              },
              [
                ['SOLID_ERROR_CODE', data?.error?.code],
                ['SOLID_ERROR_MESSAGE', data?.error?.recommended_message_for_user],
                ['SOLID_METHOD', data?.order?.method],
              ],
            );
          }

          analytic.customData('purchase__fail', {
            // @ts-ignore
            tariff: currentProduct?.id,
            // @ts-ignore
            currency: data?.order.currency,
            payment: paymentMethod,
            event_label: data?.error?.recommended_message_for_user,
            card_type: null,
          });
          initPayPal();

          localStorage.setItem('paymentFailed', pageInfo.name);
        } else {
          localStorage.removeItem('paymentFailed');
          dispatch(changePaymentMethod(PAYPAL_LITERAL));
          validatePayment('payPal', meta.payment_method, data);
        }
      },
      errorHandler: (e: Error) => {
        console.error(e);
        dispatch(setLoadingStatus(false));
      },
      onClickHandler: () => {
        analytic.customData('checkout__any__click', {
          // @ts-ignore
          tariff: currentProduct?.id,
          screen_id: pageInfo.name,
          payment: 'PayPal',
          card_type: null,
        });

        dispatch(setLoadingStatus(true));
      },
      readyHandler: () => {
        dispatch(setLoadingStatus(false));
      },
    };

    const payload = {
      // @ts-ignore
      ...currentProduct,
      ...meta,
      payment_method: PAYMENT_TYPES.PAYPAL,
    };

    // @ts-ignore
    dispatch(initPayPalAction(payload));
  }

  function initPaymentGenerator() {
    const metaBank = {
      userId: Number(user_id),
      country,
      currency: getCurrencyName(currency),
    };

    const payload = { ...(currentProduct as object), ...metaBank };

    // @ts-ignore
    dispatch(initPaymentGeneratorAction(payload));
  }

  return {
    paymentType,
    isLoading,
    handleChangePaymentMethod,
    payment: {
      initPayPal,
      initPaymentGenerator,
    },
  };
}
