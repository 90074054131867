import React, { useState } from 'react';
import cx from 'classnames';

import { Text } from 'libComponents';

import styles from './ChipItem.module.css';

import { IChipItem, IOnChangeOption } from 'types/pages/commonTypes';

const ChipItem = ({ title, onClick, className, value: inVal,  disabled = false }: IChipItem) => {
  const [checked, setChecked] = useState(false);

  const onChange = ({ target: { value } }: IOnChangeOption) => {
    setChecked(!checked);

    if (onClick) onClick(value, !checked);
  };

  return (
    <div className={cx(styles.container, className)}>
      <input type='checkbox' onChange={onChange} className={styles.checkbox} value={inVal} disabled={disabled} />
      <div className={cx(styles.option, { [styles.checked]: checked })}>
        <div className={styles.titleContainer}>
          <Text className={cx({ [styles.active]: checked })}>{title}</Text>
        </div>
      </div>
    </div>
  );
};

export default ChipItem;
