import { Navigate } from 'react-router-dom';
import React from 'react';

import lazyWithRetry from 'helpers/lazyWithRetry';

import PreloadTestania from 'pages/GeneralPages/PreloaderTestania';

import routesMap from '../routesMap';


const pathWithRegularExpression = (path) => `/${path}`;

const generateRoute = (route, pathToFile) => {
  const PageComponent = lazyWithRetry(() => import(`pages/${pathToFile}`));

  return {
    path: pathWithRegularExpression(route),
    key: route,
    element: <PageComponent key={route} />,
  };
};

const routes = [
  {
    path: pathWithRegularExpression(''),
    key: 'preload-testania',
    element: <PreloadTestania />,
    exact: true,
  },
];

for (const item of routesMap) {
  const { route, path } = item;
  const payload = generateRoute(route, path);

  routes.push(payload);
}

routes.push({
  path: '*',
  key: 'redirect-public',
  element: <Navigate to='/' />,
});

export default routes;
