import { useSelector } from 'react-redux';

import { selectUser } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';

import { PAYMENT_TYPES } from 'constants/payments';

import { IUsePaymentData } from 'interfaces/Payments/paymentMethods';

const DEFAULT_PRICE = 1;

export function usePaymentData(): IUsePaymentData {
  const { user_id, country } = useSelector(selectUser);
  const { currentProduct, paymentMethod, currency, merchantData } = useSelector(selectPayment);

  return {
    merchantData,
    currentProduct,
    paymentData: {
      // @ts-ignore
      userId: user_id,
      payment_method: PAYMENT_TYPES[paymentMethod], // probably here always be card method = 1, need check
      // @ts-ignore
      price: currentProduct?.price || DEFAULT_PRICE,
      country,
      currency: currency.name,
      payment_method_name: paymentMethod,
    },
  };
}
