import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectTestania = (state: RootState) => state.testania;

const selectTestaniaName = createSelector(selectTestania, (testania) => testania.testaniaName);
const selectCurrantBranchName = createSelector(selectTestania, (testania) => testania.currantBranchName);
const selectFlowLink = createSelector(selectTestania, (testania) => testania.flowLink);
const selectOnboardingConfig = createSelector(selectTestania, (testania) => testania.onboardingConfig);

export {
  selectTestania,
  selectTestaniaName,
  selectCurrantBranchName,
  selectFlowLink,
  selectOnboardingConfig,
};
