
// config

// services

// types

// constants
import { ERROR_LEVEL_FATAL, SENTRY_AXIOS } from 'constants/sentry';
import axios, { AxiosInstance } from 'axios';

import { CURRENT_VERSION } from 'constants/versions';
import { DEFAULT_COUNTRY } from 'constants/countriesList';

import sentry from 'services/Sentry';

// helpers
import { deleteConfig, generateQueryParams, generateQueryParamsForRefresh } from 'helpers/utilsUpdated';

import { RequestOptions } from './types/request';
import { GenerateTokensData } from '../types/user/userApiInterface';

import config from '../config';

import jwt_decode from 'jwt-decode';

const PLATFORM = 'front';

export default class ApiClient {
  authToken: string;
  apiUrl: string;
  apiVersion: string;
  apiKey: string;
  isSandbox: boolean;
  isPrivate: boolean;
  timezoneOffset: number;

  constructor({ apiUrl = config.API_URL, isSandbox = false, isPrivate = false }) {
    this.authToken = localStorage.getItem('token') ? (localStorage.getItem('token') as string) : '';
    this.apiUrl = apiUrl;
    this.apiVersion = config.API_VERSION;
    this.apiKey = config.API_KEY;
    this.isSandbox = isSandbox;
    this.isPrivate = isPrivate;
    this.timezoneOffset = Math.abs(new Date().getTimezoneOffset()) * 60;
  }

  async get<ResponseData>(url: string, params?: Record<string, string>, headers?: Record<string, string | boolean>) {
    return this.request<unknown, ResponseData>({
      url,
      params,
      headers,
      method: 'GET',
      data: {},
    });
  }

  async post<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
    return this.request<RequestData, ResponseData>({
      url,
      method: 'POST',
      data: payload,
    });
  }

  async put<RequestData>(url: string, payload: Partial<RequestData> = {}) {
    return this.request<RequestData>({
      url,
      method: 'PUT',
      data: payload,
    });
  }

  async patch<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
    return this.request<RequestData, ResponseData>({
      url,
      method: 'PATCH',
      data: payload,
    });
  }

  async delete<RequestData>(url: string, payload: Partial<RequestData> = {}) {
    return this.request<RequestData>({
      url,
      method: 'DELETE',
      data: payload,
    });
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  getToken() {
    return localStorage.getItem('token') || '';
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token') || '';
  }

  getWebToken() {
    return localStorage.getItem('web_token') || '';
  }

  getLanguage() {
    return localStorage.getItem('language') || 'en';
  }

  getUUID() {
    return localStorage.getItem('uuid') || '';
  }

  getCountry() {
    return localStorage.getItem('country') || DEFAULT_COUNTRY;
  }

  setHeaders(axiosInstance: AxiosInstance, token?: string) {
    if (token) {
      axiosInstance.defaults.headers.common['authorization'] = token;
    }
    axiosInstance.defaults.headers.common['timezone-offset'] = this.timezoneOffset;
    axiosInstance.defaults.headers.common['platform'] = PLATFORM;
    axiosInstance.defaults.headers.common['client-version'] = CURRENT_VERSION;
    axiosInstance.defaults.headers.common['locale'] = `${this.getLanguage()}_${this.getCountry()}`;
    axiosInstance.defaults.headers.common['language'] = this.getLanguage();
    axiosInstance.defaults.headers.common['version'] = this.apiVersion;
    axiosInstance.defaults.headers.common['x-api-key'] = this.apiKey;
    axiosInstance.defaults.headers.common['uuid'] = this.getUUID();
  }

  async generateTokens(payload: GenerateTokensData) {
    const { refresh_token } = payload;
    const axiosInstance: AxiosInstance = axios.create({
      baseURL: this.apiUrl,
      headers: {
        version: this.apiVersion,
      },
    });

    this.setHeaders(axiosInstance);

    try {
      const response = await axiosInstance.post('/generate-tokens', {
        refresh_token,
      });

      const { headers } = response;
      localStorage.setItem('token', headers.access_token);
      localStorage.setItem('refresh_token', headers.refresh_token);
      axiosInstance.defaults.headers.common['authorization'] = this.getToken();

      return {
        token: headers.access_token,
        refresh_token: headers.refresh_token,
      };

    } catch (error) {
      const queryParams = generateQueryParamsForRefresh();

      deleteConfig();
      if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

      location.reload();
    }
  }

  async request<RequestData, ResponseData = unknown>(
    options: RequestOptions<RequestData>,
  ): Promise<ResponseData | unknown> {
    const tmpOptions = { ...options, url: `/${options.url}` };

    const axiosInstance: AxiosInstance = axios.create({
      baseURL: this.apiUrl,
    });

    const token = this.getToken();
    const queryParams = generateQueryParams();

    token && this.setAuthToken(token);

    if (!this.authToken && this.isPrivate) {
      sentry.logError(new Error('Required token is missing'), SENTRY_AXIOS, ERROR_LEVEL_FATAL, {
        label: 'Token Required',
      });

      console.error('Required token is missing');

      return Promise.reject('Token Required');
    }

    this.setHeaders(axiosInstance, token);

    const { common } = axiosInstance.defaults.headers;

    if (!common['version'] || !common['x-api-key'] || !common['language'] || !common['platform']) {
      sentry.logError(new Error('Required header is missing'), SENTRY_AXIOS, ERROR_LEVEL_FATAL, {
        ...common,
      });

      console.error('Required header is missing');

      return Promise.reject('Required header is missing');
    }

    if (!tmpOptions.params) {
      tmpOptions.params = {};
    }

    if (this.authToken && this.isPrivate) {
      tmpOptions.headers = {
        ...tmpOptions.headers,
        token: this.authToken,
      };
    }

    if (this.isSandbox) {
      tmpOptions.headers = {
        ...tmpOptions.headers,
        sandbox: this.isSandbox,
      };
    }

    tmpOptions.headers = {
      ...tmpOptions.headers,
      'Content-Type': 'application/json',
      'version': this.apiVersion,
    };

    if (token) {
      // const refreshToken = this.getRefreshToken();
      const decodedToken: any = jwt_decode(token);
      const currentDate = Date.now() / 1000;

      // For testing, token will expire after 1 min
      // if (decodedToken.exp - 3540 < currentDate) {
      if (decodedToken.exp < currentDate) {

        deleteConfig();
        if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

        location.reload();

        // delete axiosInstance.defaults.headers.common['authorization'];
        // const response = await axiosInstance.post('/generate-tokens', {
        //   refresh_token: refreshToken,
        // });
        // const { headers } = response;
        // localStorage.setItem('token', headers.access_token);
        // localStorage.setItem('refresh_token', headers.refresh_token);
        // axiosInstance.defaults.headers.common['authorization'] = this.getToken();
      }
    }

    try {
      const response = await axiosInstance(tmpOptions);

      if (response.headers.access_token) response.data.token = response.headers.access_token;
      if (response.headers.refresh_token) response.data.refresh_token = response.headers.refresh_token;
      if (response.headers.web_token) response.data.web_token = response.headers.web_token;
      if (response.headers.country) response.data.country = response.headers.country;

      return Promise.resolve(response.data);
    } catch (error) {
      const status = error?.response?.status;

      if (status === 401) {
        deleteConfig();

        if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

        location.reload();
      }

      if (status >= 500) {
        sentry.logError(error, SENTRY_AXIOS, ERROR_LEVEL_FATAL, { ...tmpOptions, ...error?.response });
      }

      console.error('Server Error', error?.response || '');

      return Promise.reject(error?.response || 'Untracked error');
    }
  }
}
