import React, { memo, useEffect } from 'react';
import Payment from '@solidgate/react-sdk';

// types
import { ISolidPaymentFormProps } from '../../interfaces/Payments/paymentMethods';

const SolidPaymentForm = (props: ISolidPaymentFormProps) => {
  const { merchantData, setHasPaymentError } = props;

  useEffect(() => {
    if (merchantData?.signature) {
      setHasPaymentError?.(false);
    }
  }, [merchantData?.signature]);

  return <>{merchantData?.signature && <Payment {...props} />}</>;
};

function areEqual(prevProps: ISolidPaymentFormProps, nextProps: ISolidPaymentFormProps) {
  return (
    prevProps.merchantData?.signature === nextProps.merchantData?.signature &&
    prevProps.isOpenModal === nextProps.isOpenModal &&
    prevProps.onFail === nextProps.onFail &&
    prevProps.onSuccess === nextProps.onSuccess
  );
}

export default memo(SolidPaymentForm, areEqual);
