import React, { ReactElement, useState } from 'react';
import cx from 'classnames';

import { isEmpty } from 'helpers/utilsUpdated';

import ObCtaBtn from 'components/ObCtaBtn';

import styles from './ChipsSelect.module.css';

import { IChipSelect, IOption } from 'types/pages/commonTypes';

import ChipItem from './ChipItem';


const ChipsSelect = ({ optionsData, onClick, buttonData, className }: IChipSelect): ReactElement => {
  const [multipleValue, setMultipleValue] = useState([] as Array<string>);

  const multipleHandleOption = (value: string, checked: boolean) => {
    const resultArr = [...multipleValue];

    if (!checked) {
      const deleteIndex = resultArr.findIndex(element => element === value);
      resultArr.splice(deleteIndex, 1);
    } else {
      resultArr.push(value);
    }
    setMultipleValue(resultArr);
  };

  const handleOption = (value: string, checked: boolean) => multipleHandleOption(value, checked);

  return (
    <>
      <div className={cx(styles.wrapper, className)}>
        {optionsData?.map(({ title, value, className }: IOption, index: number): ReactElement =>
          <ChipItem className={className}  key={index} title={title} value={value} onClick={handleOption} />,
        )}
      </div>
      {buttonData &&  (
        <ObCtaBtn className={cx(styles.continueBtn)} label={buttonData.text} disabled={isEmpty(multipleValue)} onClick={() => onClick && onClick(multipleValue)} />)}
    </>
  );
};

export default ChipsSelect;
