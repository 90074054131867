import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { selectPayment } from 'redux/Payment/selectors';
import {
  initOneClickPayment,
  setCurrentProductCode,
  setPreviousProduct,
  setResubModalConfig,
  setUpsellProductId,
  setUpsellProductPrice,
} from 'redux/Payment/actions';

import { CREDIT_CARD_LITERAL, PAYPAL_LITERAL } from 'constants/payments';

import usePaymentFlow from 'hooks/usePaymentFlow';

import { generateQueryParams, getPriceFromCents, getToFixedNumber } from 'helpers/utilsUpdated';

import { CurrentProduct } from 'types/payments/payments';

import { useAnalytics } from 'hookExperiment';

const usePaymentResubModal = () => {
  const {
    currency,
    paymentMethod,
    previousProduct,
    currentProduct,
    resubModalConfig: {
      resubProduct3m,
      resubProduct1m,
      isOpen,
      isLatamCountry,
      toNextPage,
    },
  } = useSelector(selectPayment);

  const dispatch = useDispatch();

  const { currentScreen } = usePaymentFlow();

  const isOpenPopup = !isLatamCountry && isOpen && currentScreen?.config?.is_resubscribe_popup;

  const [isLoading, setIsLoading] = useState(false);

  const { customData } = useAnalytics(true);

  const navigate = useNavigate();

  const product3m = resubProduct3m as CurrentProduct;
  const product1m = resubProduct1m as CurrentProduct;

  const { sign } = currency;

  const price1m = getToFixedNumber(getPriceFromCents(product1m?.price + product1m?.start_price * 2), 2);
  const price3m = getToFixedNumber(getPriceFromCents(product1m?.price + product3m?.price), 2);

  const price1mBar = getToFixedNumber(price1m, 0);
  const price3mBar = getToFixedNumber(price3m, 0);

  const discountPercent = getToFixedNumber((+price1m - +price3m) / +price1m * 100, 0);

  const discount = getToFixedNumber(+price1m - +price3m, 2);
  const currentDep = getToFixedNumber(getPriceFromCents(product1m?.price), 2);

  const total = getToFixedNumber(+price3m - +currentDep, 2);

  const isOneClick = [PAYPAL_LITERAL, CREDIT_CARD_LITERAL].includes(paymentMethod);

  const url = currentScreen?.name ? 'upsell_resubscribe_discount' : 'upsell_resubscribe';

  const queryParams = generateQueryParams();

  const oneClickPayment = async () => {
    dispatch(setCurrentProductCode(product3m.product_code));
    dispatch(setUpsellProductPrice(product3m.price));
    dispatch(setUpsellProductId(product3m.name));

    if (!previousProduct) {
      dispatch(setPreviousProduct(product3m));
    }

    setIsLoading(true);

    const meta = {
      resubscribe: true,
      screenId: location.pathname,
      toNextPage: () => {
        toNextPage?.({ isValid: true });
        dispatch(setResubModalConfig({ isOpen: false, isResubscribed: true }));
      },
      onError: () => {
        localStorage.setItem('paymentFailed', currentScreen?.name);
        navigate({
          pathname: '/payment-failed',
          search: queryParams,
        });
      },
    };

    customData('upsell_resubscribe__any__click', {
      event_label: 'add_to_plan',
      screen_id: currentScreen?.name,
    });

    try {
      dispatch(initOneClickPayment(meta));
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onSkipClickHandler = () => {
    customData('upsell_resubscribe__any__click', {
      event_label: 'skip',
      screen_id: currentScreen?.name,
    });

    toNextPage?.({ isValid: false });
  };

  return {
    discountPercent,
    price1mBar,
    price3mBar,
    price1m,
    price3m,
    currentDep,
    discount,
    total,
    sign,
    isOneClick,
    isLoading,
    resubProduct3m,
    currentProduct,
    isOpen: isOpenPopup,
    url,
    oneClickPayment,
    onSkipClickHandler,
    toNextPage,
  };
};

export default usePaymentResubModal;
