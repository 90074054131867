import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { skipOnboarding } from '../../redux/Onboadring/actions';

import { handleCombinedCodes } from '../../helpers/combinedCodes';

import config from '../../config';


const useSkipOnboarding = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (config.ENV === 'stage') {
      handleCombinedCodes(handleSkipOnboarding, ['KeyO', 'KeyB']);
      handleCombinedCodes(handleClearAllStore, ['KeyR', 'KeyC']);
    }
  }, []);

  const handleSkipOnboarding = () => {
    dispatch(
      skipOnboarding({
        reload: () => window.location.reload(),
      }),
    );
  };

  const handleClearAllStore = () => {
    const isClear = window.confirm('Store has been cleared');

    if (isClear) {
      dispatch({ type: 'RESET_STATE' });
      localStorage.clear();

      setTimeout(() => {
        window.location.reload();
      }, 700);

      return;
    }
  };

  return {
    onSkip: handleSkipOnboarding,
  };
};

export default useSkipOnboarding;
