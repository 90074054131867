import config from 'config';

import UserApi from './components/User';

import ApiClient from './apiClient';

export default function () {
    const apiClient = new ApiClient({ apiUrl: config.API_URL });
    const user = new UserApi({ apiClient });

return {
        user,
    };
}
