import { Main, SYSTEMS_NAMES } from 'wikr-core-analytics';
import { hotjar } from 'react-hotjar';

import config from 'config';

import { HOTJAR_VERSION } from 'constants/versions';
import { getPixelIdByBranchName } from 'constants/analytics';

import { getParamFromUrl } from 'helpers/utilsUpdated';

import { GoogleAds } from './Systems/Google';

const HOTJAR_NUMBER_ID = +config?.HOTJAR_ID;

export const initAmazonAnalytic = () => {
  Main.initSystems({
    activeSystems: [
      {
        name: SYSTEMS_NAMES.amazon,
        id: config.AMAZON_ID,
        config: {
          releaseDate: config.RELEASE_DATE,
          streamName: config.AWS_DELIVERY_STREAM_NAME,
          envMode: config.ENV,
        },
      },
    ],
  });
};

export const initAnalytics = (branchName: string | null) => {
  const pixelId = getPixelIdByBranchName(branchName);

  Main.initSystems({
    activeSystems: [
      {
        name: SYSTEMS_NAMES.pixel,
        id: pixelId,
      },
      {
        name: SYSTEMS_NAMES.pinterest,
        id: config.PINTEREST_ID,
      },
    ],
  });
};

export const initGoogleAnalytic = (branchName: string | null) => {
  if (branchName === 'motherhood' || branchName === 'lv') {
    GoogleAds.init({
      id: config.GOOGLE_CONVERSION_ID,
    });
  }
};


export const initHotjar = () => {
  const track = getParamFromUrl('track');

  track && HOTJAR_NUMBER_ID && hotjar.initialize(HOTJAR_NUMBER_ID, HOTJAR_VERSION);
};
