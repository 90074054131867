import React from 'react';

import useSetBackground from 'hooks/useSetBackgound';
import useOnboardingFlowForProgress from 'hooks/useOnboardingFlowForProgress';
import { useHeader } from '../Header/hooks/useHeader';

import StepHeader from '../StepHeader';
import Header from '../Header';

interface IRootHeaderProps {
  onSkip: () => void;
}

const RootHeader = ({ onSkip }: IRootHeaderProps) => {
  const { isABNewHeader } = useOnboardingFlowForProgress();
  const props = useHeader();
  useSetBackground();

  if (!isABNewHeader) {
    return (
      <Header {...props} onSkip={onSkip} />
    );
  }

  return (
    <StepHeader {...props} onSkip={onSkip} />
  );
};


export default RootHeader;
