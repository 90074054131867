import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'constants/sentry';
import { ClientSdkInstance, MessageType, SdkMessage } from '@solidgate/react-sdk';

import {
  changeExclusiveOfferState,
  setHideCustomPaymentButton,
  setIsApplePayAvailable,
  setIsGooglePayAvailable,
  setLoadingStatus,
  setPaymentErrorCode,
} from 'redux/Payment/actions';

import { PAYMENT_FORM_INIT_TIMEOUT } from 'constants/timeoutValues';
import { CREDIT_CARD_LITERAL, PAYMENT_TYPES, SOLID_ERROR_CODES } from 'constants/payments';

import { Tags } from 'services/Sentry/interfaces';
import sentry from 'services/Sentry';


import { getPaymentTypesName, getPaymentTypesNameId } from 'helpers/payments';

import { IUseSolidHandlers } from 'interfaces/Payments/paymentMethods';
import { useValidatePayment } from 'hookExperiment/useValidatePayment';




const INSUFFICIENT_FUNDS_ERROR_CODE = '3.02';

export function useSolidHandlers({
                                   analytic,
                                   paymentData,
                                   currentProduct,
                                   initPaymentGenerator,
                                   setHasPaymentError,
                                   setHasPaymentSuccess,
                                   activePayment,
                                   screenId,
                                 }: IUseSolidHandlers) {
  const dispatch = useDispatch();
  const [cardBrand, setCardBrand] = useState<string | null>(null);
  const [hasValidForm, setHasValidForm] = useState<boolean>(false);

  const validatePaymentHandler = useValidatePayment();

  const handleOnInteraction = (event: SdkMessage[MessageType.Interaction]) => {
    if ('cardForm' in event) {
      setHasValidForm(event.cardForm.isValid);
    }
  };

  const handleOnError = (error: SdkMessage[MessageType.Error]) => {
    // TODO: fix
    // @ts-ignore
    if (error.value.code) {
      dispatch(
        setPaymentErrorCode({
          // TODO: fix
          // @ts-ignore
          error_code: error.value.code,
          // @ts-ignore
          product_name: currentProduct?.name,
          currency: paymentData.currency,
          // @ts-ignore
          product_price: currentProduct?.price,
        }),
      );
    }

    const customTags = getErrorCustomTags(error.value);
    // TODO: fix
    // @ts-ignore
    const errorCode = error?.value?.code ?? '';

    if (SOLID_ERROR_CODES.includes(errorCode)) {
      // @ts-ignore
      sentry.logError(error,
        SENTRY_PAYMENT,
        ERROR_LEVELS.CRITICAL,
        {
          ...(currentProduct as object),
          ...paymentData,
        },
        customTags,
      );
    }

    const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];
    // TODO: fix
    // @ts-ignore
    if (codeErrorList.includes(`${error?.value?.code}`)) {
      dispatch(changeExclusiveOfferState(true));
    }

    initPaymentGenerator &&
    setTimeout(() => {
      dispatch(setHideCustomPaymentButton(false));

      initPaymentGenerator();
    }, PAYMENT_FORM_INIT_TIMEOUT);
  };

  const getErrorCustomTags = (error: { code?: string; message?: string | string[] }) => {
    let customTags: Tags | undefined;
    const getErrorMessage = (message: string | string[]) => (typeof message === 'string' ? message : message[0]);

    if (error?.code) {
      customTags = [['SOLID_ERROR_CODE', error?.code]];
    }

    if (error?.message) {
      customTags = customTags
        ? [...customTags, ['SOLID_ERROR_MESSAGE', getErrorMessage(error?.message)]]
        : [['SOLID_ERROR_MESSAGE', getErrorMessage(error?.message)]];
    }

    return customTags;
  };

  const handleOnFail = (error: SdkMessage[MessageType.Fail]) => {
    const customTags = getErrorCustomTags(error);
    const errorCode = error?.code ?? '';
    const cardBrand = localStorage.getItem('cardBrand');

    setHasValidForm(false);

    dispatch(setHideCustomPaymentButton(true));

    if (error.code) {
      dispatch(
        setPaymentErrorCode({
          error_code: error.code,
          // @ts-ignore
          product_name: currentProduct?.name,
          currency: paymentData.currency,
          // @ts-ignore
          product_price: currentProduct?.price,
        }),
      );
    }

    setHasPaymentError?.(true);

    const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];

    if (codeErrorList.includes(errorCode)) {
      setTimeout(() => {
        dispatch(changeExclusiveOfferState(true));
      }, PAYMENT_FORM_INIT_TIMEOUT);
    }

    if (SOLID_ERROR_CODES.includes(errorCode)) {
      // @ts-ignore
      sentry.logError(error,
        SENTRY_PAYMENT,
        ERROR_LEVELS.CRITICAL,
        {
          ...(currentProduct as object),
          ...paymentData,
        },
        customTags,
      );
    }

    analytic?.customData('purchase__fail', {
      // @ts-ignore
      tariff: currentProduct?.id,
      // @ts-ignore
      currency: error?.order.currency,
      payment: getPaymentTypesName(activePayment),
      event_label: error.message,
      card_type: cardBrand,
    });
    // setTimeout(() => {
    //   dispatch(setHideCustomPaymentButton(false));
    //
    //   initPaymentGenerator();
    // }, PAYMENT_FORM_INIT_TIMEOUT);

    dispatch(setHideCustomPaymentButton(false));

    initPaymentGenerator &&
    setTimeout(() => {
      dispatch(setHideCustomPaymentButton(false));

      initPaymentGenerator();
    }, PAYMENT_FORM_INIT_TIMEOUT);

    screenId && localStorage.setItem('paymentFailed', screenId);
  };

  const handleCard = (e: SdkMessage[MessageType.Card]) => {
    localStorage.setItem('cardBrand', e.card.brand);
    setCardBrand(e.card.brand);
  };

  const handleOnSuccess = (data: SdkMessage[MessageType.Success]) => {
    localStorage.removeItem('paymentFailed');
    dispatch(setHideCustomPaymentButton(true));

    setHasPaymentSuccess?.(true);
    setHasValidForm(false);

    const isBankCardMethod = activePayment === CREDIT_CARD_LITERAL;
    const paymentNameId = getPaymentTypesNameId(activePayment);
    const paymentType = isBankCardMethod ? PAYMENT_TYPES.CREDIT_CARD : PAYMENT_TYPES.APPLE_PAY;

    validatePaymentHandler(paymentNameId, paymentType, {
      ...data,
      screen_id: screenId,
      ...(cardBrand && { brand: cardBrand }),
    });
  };

  const handleOnSubmit = () => {
    dispatch(setLoadingStatus(true));
  };

  const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
    dispatch(setHideCustomPaymentButton(false));

    const customPaymentButton = document.getElementById('customSubmitButton');

    if (customPaymentButton) {
      customPaymentButton.onclick = () => form?.submit();
    }
  };

  const handleOnMounted = (event: SdkMessage[MessageType.Mounted]) => {
    if (event.type === 'mounted') {
      if (event.entity === 'applebtn') {
        dispatch(setIsApplePayAvailable(true));
      }

      if (event.entity === 'googlebtn') {
        const domain = document.location.host;

        // TODO: remove after GP domain verification quiz.femia.health
        if (domain.includes('quiz.femia.health')) {
          return;
        }
        dispatch(setIsGooglePayAvailable(true));
      }
    }
  };

  return {
    cardBrand,
    hasValidForm,
    handleOnError,
    handleOnFail,
    handleCard,
    handleOnSuccess,
    handleOnSubmit,
    handleOnReadyPaymentInstance,
    handleOnMounted,
    handleOnInteraction,
  };
}
