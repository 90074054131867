import { combineReducers } from 'redux';

import UserReducer from './User/reducer';
import UiEffects from './UiEffects/reducer';
import TestaniaReducer from './Testania/reducer';
import PaymentReducer from './Payment/reducer';
import OnboardingReducer from './Onboadring/reducer';

const appReducer = combineReducers({
    user: UserReducer,
    testania: TestaniaReducer,
    onboarding: OnboardingReducer,
    uiEffects: UiEffects,
    payment: PaymentReducer,
});

const rootReducer = (state: any, action: any) => {
    // when a reset_state action is dispatched it will reset redux state
    if (action.type === 'RESET_STATE') {
        localStorage.removeItem('isResetStore');

        state = undefined;
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
