import React from 'react';
import classNames from 'classnames';

import config from 'config';

import styles from './Header.module.css';

import Logo from 'assets/img/Logo.svg';
import BackIcon from 'assets/img/Back.svg';

const Header = ({
                  isHideHeader,
                  isShowBackButton,
                  isDisabledSmoothScroll,
                  restrictHeader,
                  onboardingHeader,
                  handleBackButtonClick,
                  calculateProgressWidth,
                  onSkip,
                }) => {
  return (
    <If condition={restrictHeader && !isHideHeader}>
      <div className={classNames(styles.container, {
        [styles.disabledSmoothScroll]: isDisabledSmoothScroll,
      })}>
        <div className={styles.innerContainer}>
          <div className={styles.top}>
            <If condition={onboardingHeader || isShowBackButton}>
              <div className={styles.backArrow} onClick={handleBackButtonClick} role='presentation'>
                <BackIcon className={styles.iconBack} />
              </div>
            </If>
            <Logo className={styles.logo} />
            <If condition={config.ENV === 'stage' && onboardingHeader}>
              <div onClick={onSkip}>skip</div>
            </If>

            {/* <If condition={onboardingHeader}>*/}
            {/*  <div>*/}
            {/*    <div className={styles.pageCounter}>*/}
            {/*      <span className={styles.pageStart}>{currentPageCount}</span>*/}
            {/*      <span className={styles.pageSeparator}>/</span>*/}
            {/*      <span className={styles.pageEnd}>{screensNumber}</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/* </If>*/}
          </div>
          <If condition={onboardingHeader}>
            <div className={styles.progressBox}>
              <div className={styles.progressBar} style={{ width: calculateProgressWidth() }} />
            </div>
          </If>
        </div>
      </div>
    </If>
  );
};

export default Header;
