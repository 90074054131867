import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import React, { useEffect } from 'react';
import classNames from 'classnames';

import { setCurrentProduct } from 'redux/Payment/actions';

import usePaymentResubModal from './hooks/usePaymentResubModal';

import { getPriceFromCents } from 'helpers/utilsUpdated';
import { ICUPeriodNormalizer } from 'helpers/payments';

import { Box, Button, Text } from 'libComponents';
import OneClickPaymentForm from 'components/OneClickPaymentForm';
import Modal from 'components/Modal';

import styles from './PaymentResubModal.module.css';

import LogoGradient from 'assets/img/LogoGradient.svg';

import { CurrentProduct } from 'types/payments/payments';

import { useAnalytics, useScrollToTop } from 'hookExperiment';

const PaymentResubModal = () => {
  useScrollToTop();
  const { customData } = useAnalytics(true);

  const dispatch = useDispatch();

  const {
    discountPercent,
    price1mBar,
    price3mBar,
    price1m,
    currentDep,
    discount,
    total,
    sign,
    resubProduct3m,
    isLoading,
    isOneClick,
    isOpen,
    url,
    oneClickPayment,
    onSkipClickHandler,
    toNextPage,
    currentProduct,
  } = usePaymentResubModal();

  useEffect(() => {
    if (isOpen) {
      dispatch(setCurrentProduct(resubProduct3m as CurrentProduct));
      customData('upsell_resubscribe__screen__load');
    }
  }, [isOpen]);

  const ICUTrialType = ICUPeriodNormalizer(resubProduct3m?.trial as number);
  const ICUPeriodType = ICUPeriodNormalizer(resubProduct3m?.period as number);
  const startPrice = getPriceFromCents(resubProduct3m?.start_price as number);
  const disclaimer = isOneClick ? 'endFlow.paymentResubModal.checkout.disclaimer1' : 'endFlow.paymentResubModal.checkout.disclaimer2';

  return (
    <Modal
      withClose={false}
      isOpen={isOpen}
      // @ts-ignore
      withOpacity
      isPopover
      isShowModal={isOpen}
      isPageModal
    >
      <Box className={styles.container}>

        <div className={styles.titles}>
          <LogoGradient />
          <div className={styles.title}>
            <Trans i18nKey='endFlow.paymentResubModal.title' values={{
              percent: discountPercent,
            }} />
          </div>
          <Text type='small-text' className={styles.subtitle}>
            <Trans i18nKey='endFlow.paymentResubModal.subtitle1' components={{
              b: <b />,
            }} />
          </Text>
          <Text type='small-text' bold className={styles.subtitle} mb={30}>
            <Trans i18nKey='endFlow.paymentResubModal.subtitle2' components={{
              span: <span className={styles.bold} />,
            }} />
          </Text>
        </div>

        <div className={styles.bars}>
          <div className={styles.bar1m}>
            <div className={styles.bar}>
              <div className={styles.barPrice}>{sign}{price1mBar}</div>
            </div>
            <div className={styles.barText}>
              <Trans i18nKey='endFlow.paymentResubModal.bar.description1' components={{
                b: <b />,
              }} />
            </div>
          </div>
          <div className={styles.bar3m}>
            <div className={styles.bar}>
              <div className={styles.barPrice}>{sign}{price3mBar}</div>
            </div>
            <div className={styles.barText}>
              <Trans i18nKey='endFlow.paymentResubModal.bar.description2' components={{
                b: <b />,
              }} />
            </div>
          </div>
        </div>

        <div className={styles.checkout}>
          <Text bold mb={12} className={styles.title}>
            <Trans i18nKey='endFlow.paymentResubModal.checkout.title' />
          </Text>

          <div className={styles.line} />

          <div className={styles.weeksNow}>
            <Text className={styles.subtitle}>
              <Trans i18nKey='endFlow.paymentResubModal.bar.description1' components={{
                b: <></>,
              }} />
            </Text>
            <Text className={classNames(styles.price, styles.bold)}>{sign}{price1m}</Text>
          </div>

          <div className={styles.discount}>
            <Text className={styles.subtitle}>
              <Trans i18nKey='endFlow.paymentResubModal.checkout.discount' />
            </Text>
            <Text className={classNames(styles.price, styles.bold)}>-{sign}{discount}</Text>
          </div>

          <div className={styles.deposit}>
            <Text className={styles.subtitle}>
              <Trans i18nKey='endFlow.paymentResubModal.checkout.deposit' />
            </Text>
            <Text className={styles.price}>-{sign}{currentDep}</Text>
          </div>

          <div className={styles.line} />

          <div className={styles.total}>
            <Text className={styles.subtitle}>
              <Trans i18nKey='endFlow.paymentResubModal.checkout.total' />
            </Text>
            <Text className={classNames(styles.price, styles.bold)}>{sign}{total}</Text>
          </div>
        </div>

        {
          isOpen && <div className={styles.btnContainer}>
            {isOneClick ? (
              <Button
                isLoading={isLoading}
                onClick={oneClickPayment}>
                <Trans i18nKey='endFlow.paymentResubModal.checkout.button' values={{
                  currencySign: sign,
                  discount,
                }} />
              </Button>
            ) : (
              <OneClickPaymentForm toNextPage={toNextPage} url={url}
                                   upsell={currentProduct as CurrentProduct}
                                   pageName={url} />
            )}

            <div onClick={onSkipClickHandler}>
              <Text className={styles.skipBtn}>
                <Trans i18nKey='endFlow.paymentResubModal.checkout.buttonSkip' />
              </Text>
            </div>
          </div>
        }

        <div className={styles.disclaimer}>
          <Text className={styles.disclaimerText} type='caption-small'>
            <Trans i18nKey={disclaimer}
                   components={{
                     a: <a href='mailto:support-w@femia.io'>support-w@femia.io</a>,
                   }}
                   values={{
                     period: ICUPeriodType.count,
                     trial: ICUTrialType.count,
                     currencySign: sign,
                     discount,
                     total,
                     startPrice,
                   }} />
          </Text>
        </div>
      </Box>
    </Modal>
  );
};

export default PaymentResubModal;
