import config from 'config';

import ApiClientMethods from './ApiClientMethods';


export default class ApiClient {
    public: ApiClientMethods;
    private: ApiClientMethods;

    constructor({ apiUrl = config.API_URL, isSandbox = false }) {
        this.public = new ApiClientMethods({ apiUrl, isSandbox });
        this.private = new ApiClientMethods({ apiUrl, isSandbox, isPrivate: true });
    }
}
