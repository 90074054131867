import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';

import { Text } from 'libComponents';

import styles from './ObTile.module.css';

interface IObTitleProps {
  children: ReactNode;
  subtitle?: string;
  weight?: string;
  position?: string;
  bigger?: boolean;
  mb?: number;
  mt?: number;
  pl?: number;
  pr?: number;
  className?: string;
}

const ObTile = ({
                  children,
                  subtitle,
                  weight = 'w800',
                  position = 'centered',
                  mb = 32,
                  pl = 0,
                  pr = 0,
                  mt = 0,
                  bigger = false,
                  className,
                }: IObTitleProps): ReactElement => (
  <div className={styles.titlesWrapper}
       style={{ marginBottom: `${mb}px`, marginTop: `${mt}px`, paddingLeft: `${pl}px`, paddingRight: `${pr}px` }}>
    <Text title center color='text-main' type='h5'
          className={cx(styles.title, styles[weight], styles[position], { [styles.bigger]: bigger }, className)}>
      {children}
    </Text>
    <Text className={styles.subtitle}>
      {subtitle}
    </Text>
  </div>
);

export default ObTile;
