import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';


// eslint-disable-next-line import-helpers/order-imports
import {
  selectIsShowApplePayMethod,
  selectIsShowGooglePayMethod,
  selectPaymentMethod,
} from 'redux/Payment/selectors';

import { GOOGLE_PAY_LITERAL } from 'constants/payments';
import { FORM_STYLES } from 'constants/formStyles';

import api from 'services/SolidSDK/apiSingleton';

import { useSolidHandlers } from 'hooks/useSolidHandlers/useSolidHandlers';
import { usePaymentData } from 'hooks/usePaymentData/usePaymentData';

import { getApplePayButtonParams, getGooglePayButtonParams } from 'helpers/paymentMethods/config';

import { Button } from 'libComponents';
import SolidPaymentForm from 'components/SolidPaymentForm/SolidPaymentForm';
import Loader from 'components/Loader';

import styles from './OneClickPaymentForm.module.css';

import { CurrentProduct } from 'types/payments/payments';
import { MerchantDataResponseType } from 'types/payments/paymentApiInterfaces';

import { useAnalytics } from 'hookExperiment';

const cx = classNames.bind(styles);

const OneClickPaymentForm = ({
                               upsell,
                               url,
                               pageName,
                               toNextPage,
                               handleCallback,
                             }: {
  upsell: CurrentProduct;
  url: string;
  pageName: string;
  handleCallback?: () => void | undefined;
  toNextPage?: (value: { isValid: boolean }) => void;
}) => {
  const analytic = useAnalytics(true);
  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const isShowApplePayMethod = useSelector(selectIsShowApplePayMethod);
  const isShowGooglePayMethod = useSelector(selectIsShowGooglePayMethod);

  const applePayButtonParams = getApplePayButtonParams(isShowApplePayMethod);
  const googlePayButtonParams = getGooglePayButtonParams(isShowGooglePayMethod);

  const paymentMethod = useSelector(selectPaymentMethod);
  const { paymentData, currentProduct } = usePaymentData();

  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [merchantData, setMerchantData] = useState<MerchantDataResponseType | null>(null);

  const { handleOnError, handleOnSuccess, handleOnFail } = useSolidHandlers({
    currentProduct,
    paymentData,
    activePayment: paymentMethod,
    analytic,
    screenId: pageName,
  });

  useEffect(() => {
    return () => setIsPaymentProcessing(false);
  }, []);

  useEffect(() => {
    setIsFormLoading(true);
    getMerchant(upsell).then((data) => {
      setMerchantData(data);
      setIsFormLoading(false);
    });
  }, [upsell]);

  const getMerchant = async (upsell: CurrentProduct) => {
    const { product_code, price } = upsell;

    try {
      const data: MerchantDataResponseType = await api.payment.getPaymentIntentWithSignature({
        product_id: null,
        product_code: product_code,
        price: price,
        country: paymentData.country,
        currency: paymentData.currency,
      });

      return data;
    } catch (error) {
      console.log('Error');
      throw error;
    }
  };

  const handleError = (error: SdkMessage[MessageType.Error]) => {
    handleOnError(error);
  };

  const handleFail = (error: SdkMessage[MessageType.Fail]) => {
    handleOnFail(error);
  };

  const handleSuccess = (data: SdkMessage[MessageType.Success]) => {
    analytic?.customData(`${url}__any__click`, {
      event_label: 'add_to_plan',
      screen_id: pageName,
    });

    handleOnSuccess(data);

    if(handleCallback){
      handleCallback();
    }else{
      toNextPage?.({ isValid: true });
    }
  };

  const handleSubmit = () => {
    setIsPaymentProcessing(true);
  };

  const isGooglePay = Boolean(paymentMethod === GOOGLE_PAY_LITERAL);

  const googleClassName = cx('oneClickPaymentButton', {
    inactive: !isGooglePay,
  });
  const appleClassName = cx('oneClickPaymentButton', {
    inactive: isGooglePay,
  });

  if (!isFormLoading && merchantData) {
    return (
      <>
        {isPaymentProcessing && (
          <div className={styles.overlay}>
            <Loader isLoading additionalClass={styles.overlayLoader} />
          </div>
        )}
        <div ref={appleContainerRef} className={appleClassName} />
        <div ref={googleContainerRef} className={googleClassName} />

        <SolidPaymentForm
          googlePayButtonParams={googlePayButtonParams}
          googlePayContainerRef={googleContainerRef}
          applePayButtonParams={applePayButtonParams}
          applePayContainerRef={appleContainerRef}
          merchantData={{
            merchant: merchantData.merchant,
            signature: merchantData.signature,
            paymentIntent: merchantData.payment_intent,
          }}
          styles={FORM_STYLES}
          formParams={{
            enabled: false,
          }}
          onError={handleError}
          onSuccess={handleSuccess}
          onSubmit={handleSubmit}
          onFail={handleFail}
          isOpenModal
          key={merchantData?.signature}
        />
      </>
    );
  }

  return <Button isLoading disabled />;
};

export default OneClickPaymentForm;
