import {
  getDiscount,
  getMaxElemFromArr,
  getMinElemFromArr,
  getPriceFromCents,
  isEmpty,
  parsePaymentType,
  periodNormalizer,
} from 'helpers/utilsUpdated';
import lazyWithRetry from 'helpers/lazyWithRetry';

import { ICUPeriodNormalizer } from './payments';

const fromEntriesPolyfill = (iterable) => {
  return [...iterable].reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
};

export const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)?.entries();

  const params = fromEntriesPolyfill(urlSearchParams);

  return isEmpty(params) ? null : params;
};

export function lazyWithPreload(factory) {
  const Component = lazyWithRetry(factory);
  Component.preload = factory;

  return Component;
}

export const disableInputMoreThatMaxFieldLength = (element) => {
  // requires element's maxLength attribute defined
  if (element.value.length > element.maxLength) {
    element.value = element.value.slice(0, element.maxLength);
  }
};

export const getProductPaymentData = (currentProduct, t) => {
  const { trial, period, start_price, price } = currentProduct;

  const currentPaymentType = parsePaymentType(trial);
  const paymentType = trial === period ? 'SEVERAL_MONTH_PLAN' : 'ONE_WEEK_PLAN';
  const paymentLabel = periodNormalizer(period, t)?.toLowerCase();
  const duration = ICUPeriodNormalizer(period)?.context;
  const fullPrice = price === 0 ? getPriceFromCents(start_price) : getPriceFromCents(price);

  return { paymentType, currentPaymentType, paymentLabel, fullPrice, duration };
};

export const getTrimmedFields = (fields) => {
  Object.keys(fields).forEach((k) => {
    if (typeof fields[k] === 'string') {
      fields[k] = fields[k].trim();
    }
  });

  return fields;
};

export const getDiscountForPayment = (currentProduct, pageInfo) => {
  const selectedPeriod = currentProduct?.period;
  const selectedTrial = currentProduct?.trial;
  const currentPrice = currentProduct?.price;

  return getDynamicallyDiscount({ products: pageInfo?.products, selectedPeriod, selectedTrial, currentPrice });
};

export const getDynamicallyDiscount = ({ products, selectedPeriod, selectedTrial, currentPrice }) => {
  const productsByPeriod = products.filter((product) => product.period === selectedPeriod && product.trial === selectedTrial);
  const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
  const introductoryPriceMax = maxElemFromArr?.price;

  return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

// TODO - needs refactor, because upper function is a duplicate (kostyl` for new payments, fix trial -> period)
export const getDiscountForPaymentFixed = (currentProduct, pageInfo) => {
  const selectedPeriod = currentProduct?.period;
  const currentPrice = currentProduct?.price;

  return getDynamicallyDiscountFixed({ products: pageInfo?.products, selectedPeriod, currentPrice });
};

// TODO - needs refactor, because upper function is a duplicate (kostyl` for new payments, fix trial -> period)
export const getDynamicallyDiscountFixed = ({ products, selectedPeriod, currentPrice }) => {
  const productsByPeriod = products.filter((product) => product.period === selectedPeriod);
  const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
  const introductoryPriceMax = maxElemFromArr?.price;

  return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

export const getDiscountFromFirstPayment = ({ currentProductPeriod, currentProductTrial, products = [] }) => {
  const productsByPeriodFromFirstPayment = products.filter(
    (product) => product?.period === currentProductPeriod && product?.trial === currentProductTrial,
  );

  if (productsByPeriodFromFirstPayment.length < 2) {
    return 0;
  }

  const minPricedProduct = getMinElemFromArr(productsByPeriodFromFirstPayment, 'price');
  const maxPricedProduct = getMaxElemFromArr(productsByPeriodFromFirstPayment, 'price');

  return getDiscount(maxPricedProduct.price, minPricedProduct.price);
};
