export const FORM_STYLES = {
  form_body: {
    width: '100%',
  },
  submit_button: {
    'background-color': '#4C61C7',
    'font-size': '16px',
    'font-weight': 'bold',
    ':hover': {
      'background-color': '#4C61C7',
    },
    '.title-icon': {
      display: 'none',
    },
  },
  card_number: {
    input: {
      background: 'transparent',
      border: '1px solid #E0DEEC',
      'border-radius': '10px',
      color: '#322E48',
      ':focus': {
        'border-color': '#4C61C7',
      },
    },
    '.label': {
      display: 'none',
    },
    '.brand-icon': {
      top: '13px',
    },
  },
  expiry_date: {
    input: {
      background: 'transparent',
      border: '1px solid #E0DEEC',
      'border-radius': '10px',
      color: '#322E48',
      ':focus': {
        'border-color': '#4C61C7',
      },
    },
    '.label': {
      display: 'none',
    },
  },
  card_cvv: {
    input: {
      background: 'transparent',
      border: '1px solid #E0DEEC',
      'border-radius': '10px',
      color: '#322E48',
      ':focus': {
        'border-color': '#4C61C7',
      },
    },
    '.label': {
      display: 'none',
    },
    '.tooltip-icon': {
      display: 'none',
    },
  },
  zip_code: {
    'margin-bottom': '1em',
  },
  additional_field: {
    'margin-bottom': '1em',

    span: {
      display: 'none',
    },
  },
};

export const FORM_STYLES_UPDCHO = {
  form_body: {
    width: '100%',
  },
  submit_button: {
    'background-color': '#4C61C7',
    'font-size': '16px',
    'font-weight': 'bold',
    ':hover': {
      'background-color': '#4C61C7',
    },
    '.title-icon': {
      display: 'none',
    },
  },
  card_number: {
    input: {
      background: 'transparent',
      border: '1px solid #E0DEEC',
      'border-radius': '14px',
      color: '#322E48',
      ':focus': {
        'border-color': '#4C61C7',
      },
    },
    '.label': {
      display: 'none',
    },
    '.brand-icon': {
      top: '13px',
    },
  },
  expiry_date: {
    input: {
      background: 'transparent',
      border: '1px solid #E0DEEC',
      'border-radius': '14px',
      color: '#322E48',
      ':focus': {
        'border-color': '#4C61C7',
      },
    },
    '.label': {
      display: 'none',
    },
  },
  card_cvv: {
    input: {
      background: 'transparent',
      border: '1px solid #E0DEEC',
      'border-radius': '14px',
      color: '#322E48',
      ':focus': {
        'border-color': '#4C61C7',
      },
    },
    '.label': {
      display: 'none',
    },
    '.tooltip-icon': {
      display: 'none',
    },
  },
  zip_code: {
    'margin-bottom': '1em',
  },
  additional_field: {
    'margin-bottom': '1em',

    span: {
      display: 'none',
    },
  },
};
