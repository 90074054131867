import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactNode, useEffect, useRef } from 'react';

import { selectFlowLink } from 'redux/Testania/selectors';
import { setFlowLink } from 'redux/Testania/actions';
import { selectUrlParams } from 'redux/Onboadring/selectors';
import { setUrlParams as setUrlParamsToStore } from 'redux/Onboadring/actions';

import { DEFAULT_EMPTY_URL_PARAMS } from 'constants/onboardingScreensList';

import { generateQueryParams, isEmpty } from 'helpers/utilsUpdated';
import { getUrlParams as getUrlParamsFromUrl } from 'helpers/utils';

import PublicRouter from './public';
import { initOneTrust } from '../../public/services/OneTrust/initOneTrust';

// const SPLIT_PAGES = ['/ob-intro-animation', '/ob-intro-age', '/ob-intro-valueprops'];

const generalBranches = ['general', 'default'];

interface IRestrictionRouteProps {
  children: ReactNode;
}

const RestrictedRoute = ({ children }: IRestrictionRouteProps): any => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const branchName = searchParams.get('branch-name');
  // const testName = searchParams.get('test-name');
  const intro = searchParams.get('intro');
  const restricted = localStorage.getItem('yearRestriction');
  const userGoal = localStorage.getItem('userGoal');
  const urlParams = useSelector(selectUrlParams);
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);

  const urlParamsFromURL = getUrlParamsFromUrl();
  const queryParams = generateQueryParams();

  useEffect(() => {
    if (urlParamsFromURL) {
      dispatch(setUrlParamsToStore(urlParamsFromURL));
    } else {
      if (isEmpty(urlParams)) {
        dispatch(setUrlParamsToStore(DEFAULT_EMPTY_URL_PARAMS));
      }
    }
  }, []);

  // On next screens we need initialize OneTrust with delay
  useEffect(() => {
    if (location.pathname === '/' || isMountedRef.current) return;

    if (location.pathname === '/ob-intro-age-load-wo' || location.pathname === '/ob-intro-age-load-dr') {
      return;
    }

    initOneTrust();

    isMountedRef.current = true;
  }, [location.pathname]);

  if (!userGoal && intro === 'split') {
    return <Navigate to={{ pathname: '/ob-intro-animation', search: queryParams }} />;
  }

  if (!userGoal && intro === 'age') {
    return <Navigate to={{ pathname: '/ob-intro-age', search: queryParams }} />;
  }

  if (!userGoal && intro === 'age_routine') {
    return <Navigate to={{ pathname: '/ob-intro-age-routine', search: queryParams }} />;
  }

  if (!userGoal && intro === 'valueprop') {
    return <Navigate to={{ pathname: '/ob-intro-valueprops', search: queryParams }} />;
  }

  if (!userGoal && intro === 'ai') {
    return <Navigate to={{ pathname: '/ob-intro-animation-ai', search: queryParams }} />;
  }

  if (!userGoal && intro === 'trust') {
    return <Navigate to={{ pathname: '/ob-intro-animation-trust', search: queryParams }} />;
  }

  if (
    !userGoal &&
    location.pathname !== '/ob-intro-animation' &&
    location.pathname !== '/ob-intro-age' &&
    location.pathname !== '/ob-intro-age-routine' &&
    location.pathname !== '/ob-intro-valueprops' &&
    location.pathname !== '/ob-intro-animation-ai' &&
    location.pathname !== '/ob-intro-animation-trust' &&
    !location.state?.isAnimated &&
    (generalBranches.includes(branchName as string) || !branchName)
  ) {
    return <Navigate to={{ pathname: '/ob-intro-age', search: queryParams }} />;
  }

  if (!!restricted && location.pathname !== '/ob-restrictions') {
    return <Navigate to={{ pathname: '/ob-restrictions', search: queryParams }} />;
  }

  return children;
};

const Routers = () => {
  const dispatch = useDispatch();

  const flowLink = useSelector(selectFlowLink);
  const urlParams = useSelector(selectUrlParams);

  useEffect(() => {
    if (!flowLink) {
      dispatch(setFlowLink(location.href));
    }

    const urlParamsFromURL = getUrlParamsFromUrl();

    if (urlParamsFromURL) {
      dispatch(setUrlParamsToStore(urlParamsFromURL));
    } else {
      if (isEmpty(urlParams)) {
        dispatch(setUrlParamsToStore(DEFAULT_EMPTY_URL_PARAMS));
      }
    }
  }, []);

  // @ts-ignore
  return (
    // @ts-ignore
    <Routes>
      {PublicRouter.map(({ element, ...route }) => (
        <Route key={route.key} path={route.path} element={<RestrictedRoute>{element}</RestrictedRoute>}
          // @ts-ignore
               exact={route.exact} />
      ))}
    </Routes>
  );
};

export default Routers;
