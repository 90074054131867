import Core, { DataProvider } from 'testania';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useContext, useEffect, useState } from 'react';


import { selectIsAutologinComplete } from 'redux/User/selectors';
import { setCountry } from 'redux/User/actions';
import { setAppLoader } from 'redux/UiEffects/actions';
import { selectTestania } from 'redux/Testania/selectors';
import { setOnboardingConfig, setTestaniaName } from 'redux/Testania/actions';
import { setOnboardingScreensList, setUserGoal } from 'redux/Onboadring/actions';



import { DEFAULT_COUNTRY } from 'constants/countriesList';

import { deleteConfig, generateQueryParams, validateCountry } from 'helpers/utilsUpdated';
import { getUserGoalByBranchName } from 'helpers/userGoal';
import {
  clearCompleteOBsToStore,
  getIsLogin,
  getScreenList,
  getUserStatus,
  setCompleteOBsToStore,
  setCurrentBranchToStore,
  setTestaniaLoginStatusToStore,
} from './config/helpers';

import { validateFlow } from './config/flowValidation';
import { getTestaniaConfig } from './config/flowInitConfig';

import { Context } from 'App';

const generalBranches = ['general', 'default'];

const InitTestania = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const [flow, setFlow] = useContext(Context); // null

  const [searchParams] = useSearchParams();
  const queryParams = generateQueryParams();

  const branchName = searchParams.get('branch-name');
  const testName = searchParams.get('test-name');
  const prevBranchName = localStorage.getItem('branch-name');
  const prevTestName = localStorage.getItem('test-name');

  branchName && localStorage.setItem('branch-name', branchName);
  testName && localStorage.setItem('test-name', testName);

  const [startStatus, setStartStatus] = useState(false);
  const { testaniaLoginStatus, completeOBs, testaniaName } = useSelector(selectTestania);
  const isAutologinComplete = useSelector(selectIsAutologinComplete);

  const token = localStorage.getItem('token') || null;
  const yearRestriction = localStorage.getItem('yearRestriction');

  const toNextPage = async (path: string) =>
    navigate({
      pathname: path,
      search: queryParams,
    });

  const provideData = async () => {
    await DataProvider.provideInitialData({
      loginStatus: await getIsLogin(testaniaLoginStatus, token),
      startStatus,
      startAction: async () => setStartStatus(true),
      toNextPage,
      pathTestaniaPaidFinish: 'registration-page',
      pathTestaniaRegistrationFinish: 'thank-you',
      // TODO: need to add param is_password_set from BE
      getUserStatus: () => getUserStatus(dispatch, token),
      // TODO: need investigate types in lib
      // @ts-ignore
      getToken: async () => token,
      getCompleteOBs: async () => completeOBs,
      // @ts-ignore
      getCurrentBranch: async () => testaniaName,
      setCurrentBranch: async (branch: string) => setCurrentBranchToStore(branch, dispatch),
      setCompleteOBs: async (branch: string) => setCompleteOBsToStore(branch, dispatch),
      clearCompleteOBs: () => clearCompleteOBsToStore(dispatch),
      setLoginStatus: async (status: boolean) => setTestaniaLoginStatusToStore(status, dispatch),
      customFilter: validateFlow,
    });
  };

  const initTestania = async () => {
    const isGeneralFlow = generalBranches.includes(branchName as string);
    const testaniaConfig = getTestaniaConfig(flow, isGeneralFlow);
    const responseFromTestania = await Core.init(testaniaConfig);

    if (responseFromTestania) {
      const { country, testName } = responseFromTestania;
      dispatch(setAppLoader(false));

      localStorage.setItem('testaniaResponseStatus', '1');

      console.log('responseFromTestania', responseFromTestania);

      if (country) {
        const countryValue = validateCountry(country)
          ? country
          : localStorage.getItem('country') || DEFAULT_COUNTRY;

        dispatch(setCountry(countryValue));
      }
      if (testName) dispatch(setTestaniaName(testName));
    }

    await responseFromTestania?.callback();
  };

  const initTestaniaDependencies = async () => {
    dispatch(setAppLoader(true));
    await provideData();
    await initTestania();
    const onboardingFlow = DataProvider.getOnboardingFlow();

    const screenList = getScreenList(onboardingFlow);

    dispatch(setAppLoader(false));
    dispatch(setOnboardingScreensList(screenList));
    dispatch(setOnboardingConfig(onboardingFlow));
  };

  useEffect(() => {
    provideData();
  }, [token, completeOBs, testaniaName]);

  useEffect(() => {
    if (!isAutologinComplete || (generalBranches.includes(branchName as string) || !branchName) && !flow) return;

    if (branchName && !flow) {
      const userGoal = getUserGoalByBranchName(branchName);

      dispatch(setUserGoal(userGoal));
    }

    // If user entered through the another link (campaign), but we already have flow in localStorage
    // Email branch for restart flow
    if (flow && (prevBranchName !== branchName || prevTestName !== testName || branchName === 'email')) {
      localStorage.removeItem('userGoal');

      deleteConfig();
      if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

      location.reload();

      setFlow(null);

      return;
    }

    if (!yearRestriction) {
      initTestaniaDependencies();
    }
  }, [flow, isAutologinComplete]);

  return <div />;
};

export default InitTestania;
