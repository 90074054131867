import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import { GoogleAds } from 'services/Analytics/Systems/Google';

import { useAnalytics } from 'hookExperiment';


const useStartAnalytics = () => {
  const hasStartEventRef = useRef(false);
  const hasBoardingEventRef = useRef(false);
  const prevPath = useRef('');
  const { customData } = useAnalytics(true);
  const location = useLocation();

  useEffect(() => {
    if (hasStartEventRef.current && hasBoardingEventRef.current) return;
    if (prevPath.current === location.pathname || location.pathname === '/') return;

    prevPath.current = location.pathname;

    if (hasStartEventRef.current && !hasBoardingEventRef.current) {
      customData('onboarding__start', {}, true);
      GoogleAds.track('onboarding_start');
      hasBoardingEventRef.current = true;

      return;
    }
    customData('start__session');
    hasStartEventRef.current = true;

  }, [location.pathname]);
};

export default useStartAnalytics;
