import { useTranslation } from 'react-i18next';
import { FormType } from '@solidgate/react-sdk';

export const getFormParams = (isHideCustomPaymentButton: boolean) => {
  const { t } = useTranslation();

  return {
    width: '100%',
    submitButtonText: t('useSolidHandlers.submitButtonText'),
    headerText: t('useSolidHandlers.headerText'),
    titleText: t('useSolidHandlers.titleText'),
    formTypeClass: FormType.Default,
    allowSubmit: !isHideCustomPaymentButton,
    autoFocus: false,
  };
};

export const getApplePayButtonParams = (isShowApplePayMethod: boolean) => {
  return ({
    color: 'black',
    enabled: isShowApplePayMethod,
  });
};

export const getGooglePayButtonParams = (isShowGooglePayMethod: boolean) => ({
  type: 'buy',
  enabled: isShowGooglePayMethod,
});
