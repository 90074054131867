import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import { takeLatest, put } from 'redux-saga/effects';

import * as actionTypes from 'redux/Payment/actionTypes';
import { setInitPaymentData } from 'redux/Payment/actions';

import Solid from 'services/SolidSDK';

// redux

// actionTypes

// interfaces
import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

function* init({ payload }: ActionType<InitPayPal>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    try {
        const response: PaymentData = yield Solid.init('payPal', payload);

        yield put(setInitPaymentData(response));
        yield put({ type: actionTypes.SET_ORDER_ID, payload: response?.order?.order_id });
    } catch (e) {
        /* eslint-disable no-console */
        console.log('Error init');
    }
}

export const initPayPal = [takeLatest(actionTypes.INIT_PAY_PAL, init)];
