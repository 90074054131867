import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import { getPaymentFlowFromLocalStorage } from 'pages/GeneralPages/InitTestania/config/helpers';

const usePaymentFlow = () => {
  const { pathname } = useLocation();

  const paymentFlow = getPaymentFlowFromLocalStorage() || [];

  const currentLocationName = pathname.replace(/(\/([a-z]{2})\/)|\//g, '').replace(/-/g, '_');

  const currentScreen = useMemo(() => {
    return paymentFlow.find(({ id }: { id: string }) => id === currentLocationName);
  }, [pathname, paymentFlow]);

  return { paymentFlow, currentScreen };
};

export default usePaymentFlow;
