import { IGNORE_ERRORS_LIST, IGNORE_URLS_LIST } from 'sentry-utils';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Breadcrumb, BreadcrumbHint } from '@sentry/react';

import { getCustomTags } from './helpers';

import { errorType, Extras, Tags } from './interfaces';


declare interface iSentry {
  sentryDSN: string;
  env: 'stage' | 'prod' | string;
  release?: string;
  tracesSampleRate?: number;
  autoSessionTracking?: boolean;
}

const IGNORE_ERRORS_LIST_LOCAL = [
  ...IGNORE_ERRORS_LIST,
  /.*undefined is not an object (evaluating '__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost').*/g,
  /.*WKWebView API client did not respond to this postMessage'.*/g,
  /.undefined is not an object (evaluating 'ceCurrentVideo.currentTime')./g,
  /.Unexpected token 'else'./g,
  /.undefined is not an object (evaluating 'window.vampBridge.getArticleHeight').'/g,
  /.null is not a function (near '...(null)...')./g,
  /.NetworkError when attempting to fetch resource./g,
  /.Non-Error promise rejection captured with value:./g,
  /.missing frame target./g,
  /.getPercent is not defined./g,
  /.Cannot contact the gateway at this time../g,
  /.null is not a function (near '...(null)...')/g,
  /.Can't find variable: msDiscoverChatAvailable./g,
];

export default class SentryClient {
  sentryDSN: string;
  env: 'stage' | 'prod' | string;
  release?: string;
  tracesSampleRate?: number;
  private isInitiated: boolean;
  private sentry: any;

  constructor({ sentryDSN, env, release, tracesSampleRate }: iSentry) {
    this.sentryDSN = sentryDSN;
    this.env = env ?? 'stage';
    this.release = release ?? '1.0.0';
    this.tracesSampleRate = tracesSampleRate ?? 1.0;
    this.isInitiated = false;
    this.sentry = Sentry;
  }

  private static prepareErrorByCode(error: Error, type: errorType) {
    return `[${type}] | : ${JSON.stringify(error)} | ${error}`;
  }

  private static prepareBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) {
    if (breadcrumb.level === 'error' || breadcrumb.type === 'http') {
      return { ...breadcrumb, ...hint };
    }

    return null;
  }

  init() {
    if (this.isInitiated) return;

    Sentry.init({
      dsn: this.sentryDSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: this.env,
      debug: this.env !== 'prod',
      release: this.release,
      tracesSampleRate: this.tracesSampleRate,
      maxBreadcrumbs: 10,
      beforeBreadcrumb: (breadcrumb: Breadcrumb, hint: BreadcrumbHint) =>
        SentryClient.prepareBreadcrumb(breadcrumb, hint),
      ignoreErrors: IGNORE_ERRORS_LIST_LOCAL || [],
      denyUrls: IGNORE_URLS_LIST || [],
    });

    this.isInitiated = true;
  }

  // TODO fix any
  public logError(error: Error, type: errorType, errorLevel: any, extras?: Extras, customTags?: Tags) {
    const customTagsArr = customTags || getCustomTags(error, type, extras);

    this.sentry.withScope((scope: Sentry.Scope) => {
      extras && scope.setExtras({ ...extras });

      scope.setTag('ERROR_TYPE', type);
      scope.setLevel(errorLevel);

      customTagsArr.length && customTagsArr.forEach(([tag, value]) => scope.setTag(tag, value));

      if (process.env.ENV === 'production') {
        this.sentry.captureMessage(SentryClient.prepareErrorByCode(error, type));
      }
    });
  }
}
