/* eslint-disable no-console */
import { Main } from 'wikr-core-analytics';
import { call, put, select, takeLatest } from 'redux-saga/effects';

// redux
import { selectUserId } from 'redux/User/selectors';
import { SET_IS_PAID } from 'redux/User/actionTypes';
// eslint-disable-next-line import-helpers/order-imports
import { selectFlowLink, selectTestaniaName } from 'redux/Testania/selectors';

// helpers


// interfaces
import { RootState } from 'redux/rootReducer';
import { selectCurrentProduct, selectPaymentMethod } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import {
  setPaymentType,
  setSubscriptionId,
  setValidatePaymentData,
  setValidatePaymentFail,
  validatePayment as validatePaymentAction,
} from 'redux/Payment/actions';

// constants
import { setIsPaid } from '../../User/actions';

import { APP_FULL_ACCESS, PAYMENT_TYPES_NAME } from 'constants/payments';

import Solid from 'services/SolidSDK';
import { GoogleAds } from 'services/Analytics/Systems/Google';

import { cleanObject, deleteSlash, fromPennyToInt, getPriceFromCents } from 'helpers/utilsUpdated';

import { ValidatePayload, ValidateResponse } from 'types/payments/validate';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';

const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const testaniaName = (state: RootState) => selectTestaniaName(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
  // @ts-ignore
  yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

  const order = payload.data?.order;

  const currentProduct: CurrentProduct = yield select(getCurrentProduct);
  const flow_link: string = yield select(getFlowLink);

  yield put(setPaymentType(currentProduct?.payment_type));

  const isTrial = !!currentProduct?.trial;

  const pageUrl = deleteSlash(payload.screenId);
  const ab_test_name: string = yield select(testaniaName);

  const meta = {
    payment_method: payload.paymentMethod || 1,
    payment_type: currentProduct.payment_type,
    product_code: APP_FULL_ACCESS,
    ...(!flow_link.includes('localhost') && { flow_link }),
    ...(ab_test_name && { ab_test_name }),
    ...(pageUrl && { payment_screen: pageUrl }),
    order_value: order,
    subscription_value: {
      id: order?.subscription_id,
      expired_at: new Date(),
      subscription_period: currentProduct.period,
      subscription_trial_period: currentProduct.trial,
    },
  };

  try {
    const response: ValidateResponse = yield Solid.validate(payload?.system, meta);

    payload.data.amountWithoutCommission = Number(getPriceFromCents(currentProduct?.ltv));
    payload.data.isIntroductory = isTrial;
    payload.data.introductorySubscriptionPrice = currentProduct?.start_price;

    if (response.status !== 'ok') return response;

    yield call(sendAnalyticPurchase, payload);

    yield put({ type: SET_IS_PAID, payload: true });
    if (order?.subscription_id) yield put(setSubscriptionId(order.subscription_id));
    // yield put(setValidatePaymentData({ ...response, result: true }));
    yield put(setValidatePaymentData({ result: true }));
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    yield put(setIsPaid(true));
  } catch ({ error }) {
    console.error('error', error);
    alert('Something went wrong with payment validate: ' + error);

    yield put(setValidatePaymentFail(error));
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
  }
}

function* sendAnalyticPurchase(payload: ValidatePayload) {
  const {
    amountWithoutCommission,
    isIntroductory,
    introductorySubscriptionPrice,
    order: { amount, currency, subscription_id },
    brand,
    screen_id,
  } = payload.data;

  const product: CurrentProduct = yield select(getCurrentProduct);
  const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
  const userId: number = yield select(getUserId);
  const ab_test_name: string = yield select(testaniaName);
  // const urlParams = generateQueryParams();

  const content_id = isIntroductory
    ? `Product - Introductory Price ${fromPennyToInt(amount)} - Subscription price ${fromPennyToInt(
      introductorySubscriptionPrice,
    )}`
    : `Product - Price ${fromPennyToInt(amount)}`;

  const options = {
    currency: currency,
    value: amountWithoutCommission,
    content_id,
    subscription_price: fromPennyToInt(introductorySubscriptionPrice),
    price: fromPennyToInt(amount),
    payment: PAYMENT_TYPES_NAME[paymentMethod],
    card_type: brand,
    // urlParams,
    user_id: userId,
    order_id: payload?.data?.order?.order_id,
    subscription_id: subscription_id,
    screen_id,
    tariff: product.id,
    ab_test_name,
    order_type: product?.payment_type,
  };

  const gaData = {
    value: amountWithoutCommission,
    currency,
    order_id: payload?.data?.order?.order_id,
  };

  Main.purchase(cleanObject(options));
  GoogleAds.purchase(gaData);
}

export const validatePayment = [takeLatest(actionTypes.VALIDATE_PAYMENT, validate)];
