import api from 'apiSingleton';

import { setIsPaid, setUserData } from 'redux/User/actions';
import { clearCompleteOBs, setCompleteOBs, setCurrentBranchName, setTestaniaLoginStatus } from 'redux/Testania/actions';

import { onboardingScreensList } from 'constants/onboardingScreensList';

import { isEmpty, replaceUnderscore } from 'helpers/utilsUpdated';


export const getBranchNameFromLocalStorage = () => {
  return localStorage.getItem('config') && JSON.parse(localStorage.getItem('config'))?.branch_name;
};

export const getPaymentFlowFromLocalStorage = () => {
  return localStorage.getItem('config') && JSON.parse(localStorage.getItem('config'))?.payment_flow;
};

export const getConfigBy = (name, defaultValue) => {
  const params = new URLSearchParams(window.location.search);

  return params.get(name) || getBranchNameFromLocalStorage() || defaultValue;
};

export const getConfigFromLocalStorageBy = (name, defaultValue) => {
  const param = localStorage.getItem(name);

  return param || getBranchNameFromLocalStorage() || defaultValue;
};

export const getVariantBranchName = () => {
  const params = new URLSearchParams(window.location.search);

  return params.get('variant-name');
};

export const getIsLogin = async (testaniaLoginStatus, token) => {
  return testaniaLoginStatus === 'true' || Boolean(token) || false;
};

export const getCheckFlow = (payment_flow) => (flowId) => payment_flow.find((item) => item.id === flowId);

export const getValidatedStatus = (rules) => {
  for (const rule of rules) {
    if (!rule.exec(rule.args)) {
      /* eslint-disable no-console */
      console.log('%c Flow validation error - ' + rule.message, 'color:red;');

      return false;
    }
  }

  return true;
};

export const getScreenList = (onboardingFlow) => {
  let count = 1;

  return onboardingFlow.reduce((screenList, { name }) => {
    const onboardingFlowPage = replaceUnderscore(name);

    if (onboardingScreensList.includes(onboardingFlowPage)) {
      return { ...screenList, [onboardingFlowPage]: { index: count++ } };
    }

    return screenList;
  }, {});
};
export const setCurrentBranchToStore = async (branch, dispatch) => {
  dispatch(setCurrentBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
  dispatch(setCompleteOBs(branch));
};

export const clearCompleteOBsToStore = async (dispatch) => {
  dispatch(clearCompleteOBs());
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
  dispatch(setTestaniaLoginStatus(status));
};

const getUserSetData = async (dispatch) => {
  let user = {};

  try {
    user = await api.user.getUser();

    !isEmpty(user) && dispatch(setUserData(user));
  } catch (e) {
    console.error('getUser error', e);
  }

  return { isPaid: Boolean(user?.is_paid), isPasswordSet: Boolean(user?.is_password) };
};

export const getUserStatus = async (dispatch, token) => {
  const userStatus = token ? await getUserSetData(dispatch) : { isPaid: false, isPasswordSet: false };

  dispatch(setIsPaid(userStatus?.isPaid));

  return userStatus;
};

