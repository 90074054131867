import { useEffect } from 'react';

export function useClearStorage(key: string) {
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem(key);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
}
