import api from '../apiSingleton';

// helpers
import { normalizeObject, toUnderScore } from '../helpers/utils';

// interfaces
import { InitPayload, OneClickPayment, ReplaceSubscription, ValidatePayment } from '../interfaces/payment';

abstract class AbstractPayment {
  public name: string;

  protected constructor(name: string) {
    this.name = name;
  }

  async subscriptionNormalize(payload: any) {
    const subKeys = [
      'payment_method',
      'order_description',
      'currency',
      'price',
      'product_id',
      'form_design_name',
      'country',
    ];

    return await normalizeObject(subKeys, payload);
  }

  public async initPayment(payload: InitPayload) {
    const normalizePayload = await this.normalizeInitData(payload);

    return await api.payment.init(normalizePayload);
  }

  public async validatePayment(payload: ValidatePayment) {
    return await api.payment.validate(payload);
  }

  public async validateResubscribe(payload: ValidatePayment) {
    return await api.payment.validateResubscribe(payload);
  }

  public async validateApplePayMerchant(validationUrl: object) {
    return await api.payment.validateApplePayMerchant(validationUrl);
  }

  public async isSupport(paymentType: string): Promise<boolean> {
    return paymentType === this.name;
  }

  public replaceSubscription(meta: ReplaceSubscription) {
    return api.payment.replaceSubscription(meta);
  }

  public oneClickPayment(payload: OneClickPayment) {
    return api.payment.oneClickPayment(payload);
  }

  protected async normalizeInitData(data: any) {
    const underscoreKeysObj = await toUnderScore(data);
    if (data.payment_type === 'lifetime') {
      return await this.lifetimeNormalize(underscoreKeysObj);
    } else if (data.payment_type === 'subscription') {
      return await this.subscriptionNormalize(underscoreKeysObj);
    }

    return data;
  }

  private async lifetimeNormalize(payload: any) {
    const subKeys = [
      'payment_method',
      'price',
      'order_description',
      'currency',
    ];

    return await normalizeObject(subKeys, payload);
  }
}

export default AbstractPayment;
