import { Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTICS } from 'constants/sentry';

import { setPlatform } from 'redux/User/actions';

import sentry from 'services/Sentry';

import { getDeviceOS } from 'helpers/utilsUpdated';


import { Dispatch } from 'types/commonInterfaces';


const getResolveParams = async (value: string | null) => {
  return new Promise((resolve: (value: string | null) => void ) => {
    resolve(value);
  });
};

export const setAnalyticData = async ({ country, abTestName, userId }: any) => {
  if (userId) {
    await Provider.setUserId(Number(userId));
  }

  await Provider.provideData({
    country: async () => await getResolveParams(country),
    abTestName: async () => await getResolveParams(abTestName),
    clientHandlerFn: (error: Error) => sentry.logError(error, SENTRY_ANALYTICS, ERROR_LEVELS.ERROR, {}),
  });
};

export const setUserPlatform = (dispatch: Dispatch) => {
  const platform = getDeviceOS();

  dispatch(setPlatform(platform));
};
